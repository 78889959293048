import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn' // 导入本地化语言
dayjs.locale('zh-cn')
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm'
const DATE_FORMAT = 'YYYY-MM-DD'
const MONTH_FORMAT = 'YYYY-MM'

// 获取年月日时分
export function formatToDateTime(date: dayjs.ConfigType = undefined, format = DATE_TIME_FORMAT): string {
  return dayjs(date).format(format)
}
// 获取年月日
export function formatToDate(date: dayjs.ConfigType = undefined, format = DATE_FORMAT): string {
  return dayjs(date).format(format)
}
// 获取年月
export function formatToMonth(date: dayjs.ConfigType = undefined, format = MONTH_FORMAT): string {
  return dayjs(date).format(format)
}

export function dateFromNow(date: string) {
  return dayjs(date).fromNow()
}

// 传入一个日期，返回周几
export function dateToChineseDay(date: string) {
  if (!date) {
    return date
  }
  const dayTexts = ['周日', '周一', '周二', '周三', '周四', '周五']
  const index = dayjs(date).day()
  return dayTexts[index]
}

export const dateUtil = dayjs
